import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import NOT_FOUNT_IMG from "../../static/404.jpeg"

export default function NotFount() {
  return (
    <Layout pageType="">
      <Seo title="404 Not Fount" description="ページがみつかりません" />
      <div>
        <h1>404 Not Found</h1>
        <Link to="/">トップページへ</Link>
      </div>
      <img src={NOT_FOUNT_IMG} alt="not found" />
    </Layout>
  )
}